import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import useLiveControl from "./../controlled/useLiveControl"

const CareerPage = ({ data }) => {


  const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Events"}
        description={
          "EnerBank USA is an active member at events in the home improvement space. Visit us at the next event and come meet our team!"
        }
      />
      <Helmet>
        <body className="page-events" />
      </Helmet>
      {isStaging ? (
        <>
      <section  className="banner-area pos_relative" id="home">
        <div className="container banner-container"
          style={{
                    backgroundImage:
                      "url(" +
                      "http://gbdevpress.enerbank.com/wp-content/uploads/2024/07/upcomming-events.jpg" +
                      ")",
                  }}
        >
          <div className="relative banner-area-text-container">
                <div className="container text-center">
                  <div className="identifier-container row-fluid">
                    <div className="element span12">
                        <div className="rhif-identifier span5">
                            <h4>HOME IMPROVEMENT FINANCING</h4>
                        </div>
                        <div className="right-angle span2"></div>
                    </div>
                  </div>
                  <h1 className="text-white text-left desktop" style={{ padding: "80 0" }}>
                    Upcoming events
                  </h1>
                </div>
              </div>
        </div>
        <div className="container text-center mobile">
          <h1 className="text-white text-left" style={{ padding: "80 0" }}>
          Upcoming events
          </h1>
        </div>
        </section>

      </>
    ) : (
      <>
      {data.wpgraphql.page.top_banner.banner.backgroundImage && (
        <section className="banner-area pos_relative" id="home">
          <img
            src={
              data.wpgraphql.page.top_banner.banner.backgroundImage.sourceUrl
            }
            className="object-fit-cover"
            alt={data.wpgraphql.page.top_banner.banner.backgroundImage.altText}
          />
        </section>
      )}
      </>
    )}
      {/* section-gap */}
      {isStaging ? (
      <section className="pt-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-center pb-5">Come meet the Regions Home Improvement Financing team!</h1>
            </div>
            <div className="col-12">
              <table className="table table-striped mb-5">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data.wpgraphql.page.events &&
                    data.wpgraphql.page.events.events.map((item, i) => (
                      <tr key={item.fieldGroupName + i}>
                        <td>{item.name}</td>
                        <td>{item.location}</td>
                        <td>{item.date}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      ) : (
        <section className="container">
        <div
          className="text-center my-5"
          dangerouslySetInnerHTML={{ __html: data.wpgraphql.page.content }}
        />
        <div className="row">
          <div className="col-12">
            <table className="table table-striped mb-5">
              <thead>
                <tr>
                  <th className="text-blue">Name</th>
                  <th className="text-blue">Location</th>
                  <th className="text-blue">Date</th>
                </tr>
              </thead>
              <tbody>
                {data.wpgraphql.page.events &&
                  data.wpgraphql.page.events.events.map((item, i) => (
                    <tr key={item.fieldGroupName + i}>
                      <td>{item.name}</td>
                      <td>{item.location}</td>
                      <td>{item.date}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      )}
    </Layout>
  )
}
export default CareerPage

export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }

        top_banner {
          fieldGroupName
          banner {
            fieldGroupName
            backgroundImage {
              sourceUrl
              altText
            }
          }
        }

        events {
          events {
            date
            fieldGroupName
            location
            name
          }
        }
      }
    }
  }
`
